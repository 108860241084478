export enum RecordStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum OperationType {
  ADDITION = "Addition",
  SUBSTRACTION = "Substraction",
  MULTIPLICATION = "Multiplication",
  DIVISION = "Division",
  SQUAREROOT = "SquareRoot",
  RANDOMSTRING = "RandomString",
}
